import axios from "axios"

export const activateUser = async (login, password, puk) => {
    const {data} = await axios.post("web/newuser", {login, password, puk})
    return data;
}

export const changePassword = async (currentpassword, newpassword) => {
    const {data} = await axios.post("web/changepassword", {currentpassword, newpassword})
    return data;
}

export const auth = async (login, password) => {
    const {data} = await axios.post("web/auth", {login, password});
    return data;
}

export const dashboard = async (client) => {
    const {data} = await axios.get(`web/dashboard${client ? "?client=" + client : ""}`);
    return data;
}
