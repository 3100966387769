import React, { lazy, useEffect } from 'react';
import './App.scss';
import { BrowserRouter, HashRouter, Route, Routes, Navigate, useParams } from 'react-router-dom';
import checkAuth from './app/auth';
import initializeApp from './app/init';
import ActivateUser from "./features/user/ActivateUser";
import { useAtom } from "jotai/index";
import { webConfigAtom } from "./DataLoading";
import ErrorView from "./ErrorView";

// Importing pages
const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
const token = checkAuth();

function App() {

  const [webConfig] = useAtom(webConfigAtom);

  const Router = webConfig?.hash_router ? HashRouter : BrowserRouter;

  // Function to get the basename from the URL
  const getBasename = () => {

    const primaryLinks = ["activateuser", "login", "forgot-password", "register", "error"];

    const pathArray = window.location.pathname.split('/');

    if(pathArray[1].toLocaleLowerCase().includes("account.mvc")) return "/"

    if (pathArray.length > 1 && pathArray[1] !== 'app' && pathArray[1] !== '') {
      if(primaryLinks.includes(pathArray[1])) return "/"
      return `/${pathArray[1]}`;
    }

    return '/';
  };

  console.log("MOOOUNT")
  const basename = getBasename();

  const RedirectWithCity = () => {
    const { city } = useParams();

    console.log(city)

    if(city.toLocaleLowerCase() === "account.mvc") {
      return <Navigate to={`/${city}/account.mvc/puk`} replace />;
    } else if(city.toLocaleLowerCase() === "puk") {
      return <Navigate to={`/account.mvc/puk`} replace />;
    }
    return <Navigate to={`/${city}/app/dashboard`} replace />;
  };

  return (
    <Router basename={basename}>
      <Routes>
        <Route path="/activateuser" element={<ActivateUser />} />
        <Route path="/account.mvc/puk" element={<ActivateUser/>} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/error" element={<ErrorView />} />
        {/* Place new routes over this */}
        <Route path="/app/*" element={<Layout />} />
        <Route path="/:city" element={<RedirectWithCity />} />
        <Route path="*" element={<Navigate to="/app/dashboard" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
