import { useState, useEffect, useRef } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

function InputText({
                     labelTitle,
                     labelStyle,
                     type,
                     containerStyle,
                     defaultValue,
                     placeholder,
                     updateFormValue,
                     updateType,
                     defaultFocused,
                   }) {
  const [value, setValue] = useState(defaultValue);
  const [inputType, setInputType] = useState(type || "text");
  const inputRef = useRef(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    let timer;
    if (defaultFocused) {
      timer = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100); // Delay to ensure the modal and input are rendered
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [defaultFocused]);

  const updateInputValue = (val) => {
    setValue(val);
    if (updateFormValue) {
      updateFormValue(updateType, val);
    }
  };

  const handleInputChange = (e) => {
    updateInputValue(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setInputType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  return (
    <div className={`form-control w-full ${containerStyle}`}>
      <label className="label">
        <span
          className={`label-text text-base-content font-semibold ${labelStyle}`}
        >
          {labelTitle}
        </span>
      </label>
      <div className="relative">
        <input
          ref={inputRef}
          type={inputType}
          value={value}
          placeholder={placeholder || ""}
          onChange={handleInputChange}
          className="input input-bordered w-full pr-10"
        />
        {type === "password" && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
          >
            {inputType === "password" ? (
              <EyeIcon className="h-5 w-5 text-normal-1" />
            ) : (
              <EyeSlashIcon className="h-5 w-5 text-normal-1" />
            )}
          </button>
        )}
      </div>
    </div>
  );
}

export default InputText;
