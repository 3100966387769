function SuspenseContent(){

  const dark = localStorage.getItem("theme") === "dark";
    return(
        <div className={"w-full h-screen " + "dark:bg-base-100 dark:text-white bg-white text-black" }>
            Die Webanwendung wird geladen...
        </div>
    )
}

export default SuspenseContent
