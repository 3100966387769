import axios from "axios"
import { NotificationManager } from "react-notifications";

export const getCityFromPath = () => {

  const pathArray = window.location.pathname.split('/');
  if (pathArray.length > 1 && pathArray[1] !== 'app' && pathArray[1] !== '') {

    if(pathArray[1] === "activateuser" || pathArray[1] === "account.mvc" || pathArray[1] === "error") return null;

    return pathArray[1];
  }
  return null;
};

const checkAuth = () => {
/*  Getting token value stored in localstorage, if token is not present we will open login page
    for all internal dashboard routes  */
    const TOKEN = localStorage.getItem("token")
    // const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"]
    // const isPublicPage = PUBLIC_ROUTES.some( r => window.location.href.includes(r))

  const city = getCityFromPath();
  const baseUniqueId = window.location.hostname === "localhost" ? "ristestneu.kic-software.de" : window.location.hostname;
  axios.defaults.headers.common['x-uniqueid'] = city ? `${baseUniqueId}/${city}` : baseUniqueId;
  axios.defaults.headers.common['x-customname'] = city ? `${city}` : '';

  axios.defaults.timeout = 5000;

    axios.interceptors.request.use(function (config) {
        // UPDATE: Add this code to show global loading indicator
        document.body.classList.add('loading-indicator');
        return config
      }, function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(function (response) {
        document.body.classList.remove('loading-indicator');
        return response;
      }, function (error) {
          document.body.classList.remove('loading-indicator');

          if(error.code === "ECONNABORTED") {
            NotificationManager.error('Server nicht erreichbar. Bitte später versuchen.');
          }

      return Promise.reject(error);
      }
    );
    if(TOKEN) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`
        return TOKEN
    }
}

export default checkAuth
